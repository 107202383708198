import React from "react";
import styled from "styled-components";

const Container = ({ children, className }) => (
	<Wrapper className={className}>{children}</Wrapper>
);

export default Container;

const Wrapper = styled.div`
	max-width: 1350px;
	margin-left: auto;
	margin-right: auto;
`;
